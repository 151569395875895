import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Row from "../components/Row";
import Col from "../components/Col";
import Container from "../components/Container";
import Section from "../components/Section";
import Title from "../components/Title";
import Paragraph from "../components/Paragraph";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Seo title="Privacy Policy" />
      <Section>
        <Container>
          <Row justify="center">
            <Col lg={8}>
              <Center>
                <Title size={1} mb={8}>
                  Privacy Policy
                </Title>
              </Center>
              <Paragraph>
                TechKosmo are committed to protecting the privacy of their data
                subjects; this policy explains how your personal information is
                handled. By using the digital platforms that are a part of
                TechKosmo, you agree to the terms of this privacy statement.
              </Paragraph>
              <Paragraph>
                This privacy policy only applies to data gathered online via
                official websites, mobile applications, and social media pages
                (collectively referred to as "digital platforms"), not data
                obtained offline. We reserve the right to change this privacy
                statement at any time by posting a new version on our website.
              </Paragraph>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

const Center = styled.div`
  text-align: center;
`;

export default PrivacyPolicy;
